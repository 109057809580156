import React from 'react';
import styled from 'styled-components';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const StyledMapContainer = styled.div`
  width: 34.8rem;
  height: 21.5rem;
  position: relative;

  @media only screen and (min-width: 1200px) {
    width: 44.875rem;
    height: 27.6rem;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 500px;
    margin-top: 4rem;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    height: 300px;
    margin-top: 3rem;
  }
`;

//AIzaSyDszPZPPgORS4ZqF0aEkwNMj2zz_n28Z1I

const GoogleMap = ({ longLat, google, styles }) => {
  return (
    <StyledMapContainer>
      <Map google={google} zoom={16} styles={styles} initialCenter={longLat}>
        <Marker position={longLat} />
      </Map>
    </StyledMapContainer>
  );
};

GoogleMap.defaultProps = {
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ],
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDszPZPPgORS4ZqF0aEkwNMj2zz_n28Z1I',
})(GoogleMap);
