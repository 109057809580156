import React from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { typography, pageMargins } from '../../shared/styles.js';
import GoogleMap from '../globals/GoogleMap';

const StyledMapAddress = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${pageMargins}
  margin-top: 5rem;
  background-color: white;

  padding-top: 4rem;
  padding-bottom: 4rem;
  & .project--info__container {
    display: flex;
    flex-direction: column;

    width: 16%;

    & h6,
    p {
      font-family: ${typography.type.secondary};
    }
    p {
      font-weight: ${typography.type.regular};
      line-height: 1.9rem;
      font-size: ${typography.size.s3};
    }
    & h6 {
      margin-top: 2rem;
      font-weight: ${typography.type.black};
      text-transform: uppercase;
      line-height: 1.4rem;
      font-size: ${typography.size.s1};
      letter-spacing: 0.1rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;

    .project--info__container {
      width: 100%;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 50% 50%;
      padding-bottom: 2rem;
      & > p:nth-child(2) {
        grid-row: 2/3;
        grid-column: 1/2;
      }
    }
  }
  @media only screen and (max-width: 640px) {
    .project--info__container {
      display: flex;
    }
  }
`;

const MapAddress = ({ longLat, text }) => {
  return (
    <StyledMapAddress>
      <div className="project--info__container">{parse(text)}</div>
      <GoogleMap longLat={longLat} />
    </StyledMapAddress>
  );
};

export default MapAddress;
