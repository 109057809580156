import React from 'react';
import { withPreview } from 'gatsby-source-prismic';
import Layout from '../components/layout';
import { color } from '../shared/styles';
import { graphql } from 'gatsby';
import HeroSection from '../components/HeroSection';
import ImageText from '../components/projects/ImageText';
import MapAddress from '../components/projects/MapAddress';
import ImageCarousel from '../components/globals/ImageCarousel';
import RelatedProjects from '../components/globals/RelatedProjects';

export const query = graphql`
  query($uid: String!) {
    prismicProjectTemplate(uid: { eq: $uid }) {
      data {
        project_title {
          html
          text
        }
        hero_image {
          url
          thumbnails
          alt
        }
        image_carousel {
          carousel_single_image {
            url
            thumbnails
            alt
            dimensions {
              width
              height
            }
          }
        }
        meta_description
        meta_title
        project_header {
          text
          html
        }
        google_embed_url {
          text
        }
        project_description {
          html
          text
        }
        project_address {
          html
          text
        }
        project_info {
          html
          text
        }
        project_long_lat {
          latitude
          longitude
        }
        project_main_image {
          url
          thumbnails
          alt
          dimensions {
            width
            height
          }
        }
        related_projects {
          link {
            uid
            document {
              ... on PrismicProjectTemplate {
                id
                data {
                  related_image {
                    url
                    thumbnails
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          title {
            text
          }
        }
      }
    }
  }
`;

const Project = ({ data }) => {
  const cms = data.prismicProjectTemplate.data;
  const goBack = e => {
    e.preventDefault();
    window.history.back();
  };

  return (
    <Layout bgColor={color.mainBg}>
      <HeroSection
        title={cms.project_title.text}
        header={cms.project_title.html}
        subheader={cms.project_address.html}
        bg={cms.hero_image.url}
        goBack={goBack}
      />
      <ImageText imgUrl={cms.project_main_image.url} text={cms.project_description.text} />

      <ImageCarousel
        size="l"
        name="project"
        images={cms.image_carousel.map(image => image.carousel_single_image).filter(x => x)}
      />
      <MapAddress
        text={cms.project_info.html}
        longLat={{ lng: cms.project_long_lat.longitude, lat: cms.project_long_lat.latitude }}
      />
      <RelatedProjects items={cms.related_projects} />
    </Layout>
  );
};

export default withPreview(Project);
